<template>
  <el-container class="index-container">
    <el-header>
      <div>
        <p>丰昌隆官网管理系统</p>
      </div>
      <div>
        <el-button type="primary"
                   icon="el-icon-message"
                   size="small"
                   @click="goMessage">询盘消息</el-button>
        <!-- loginout -->
        <el-button type="info"
                   icon="el-icon-switch-button"
                   size="small"
                   @click="logout">退出登录</el-button>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '240px'">
        <!-- 收缩按钮 -->
        <div class="toggle-button"
             @click="toggleCollapse">|||</div>
        <el-menu class="el-menu-vertical-demo"
                 background-color="#333744"
                 text-color="#f5f5f5"
                 active-text-color="#409EFF"
                 :default-active="activePath"
                 unique-opened
                 :collapse="isCollapse"
                 :collapse-transition="false"
                 router>
          <!-- 菜单 -->
          <el-menu-item index="/welcome"
                        @click="saveNavState('/welcome')">
            <i class="el-icon-s-data"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <!-- 一级菜单 -->
          <el-submenu :index="item.id + ''"
                      v-for="item in menuList"
                      :key="item.id">
            <template slot="title">
              <i :class="iconObj[item.id]"></i>
              <span>{{item.auth_name}}</span>
            </template>

            <!-- 二级菜单 -->
            <el-menu-item v-for="subItem in item.children"
                          :key="subItem.id"
                          :index="subItem.path"
                          @click="saveNavState(subItem.path)">
              <template slot="title">
                <i :class="iconObj[subItem.id]"></i>
                <span>{{subItem.auth_name}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-main>
        <router-view />
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
export default {
  data () {
    return {
      menuList: [],
      iconObj: {
        1: 'el-icon-menu',
        2: 'el-icon-picture-outline',
        3: 'el-icon-s-order',
        4: 'el-icon-s-shop',
        5: 'el-icon-s-check',
        6: 'el-icon-s-tools',
        7: 'el-icon-s-operation',
        8: 'el-icon-s-platform',
        9: 'el-icon-data-line',
        10: 'el-icon-c-scale-to-original',
        11: 'el-icon-picture-outline',
        12: 'el-icon-document-copy',
        13: 'el-icon-tickets',
        14: 'el-icon-document-copy',
        15: 'el-icon-upload',
        16: 'el-icon-folder-opened',
        17: 'el-icon-s-custom',
        18: 'el-icon-lock',
        19: 'el-icon-picture',
        20: 'el-icon-s-tools',
        21: 'el-icon-files'
      },

      isCollapse: false,
      activePath: ''
    }
  },

  methods: {
    // 退出登录
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },

    async getMenuList () {
      const { data: res } = await this.$http.get('nav')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.menuList = res.data
    },

    // 点击按键，切换菜单折叠
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },

    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },

    // 询盘信息
    goMessage () {
      this.$router.push('/message')
    }
  },

  created () {
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
  }
}
</script>

<style scoped>
.el-header {
  background-image: linear-gradient(to right, #333744, #3e4b72);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f5f5f5;
  font-size: 20px;
  font-weight: bold;
}

.el-aside {
  background-color: #333744;
}

.el-main {
  background-color: #f5f5f5;
}

.index-container {
  height: 100%;
}

.el-aside .el-menu {
  border-right: none;
}

.index-container {
  height: 100%;
}

.toggle-button {
  line-height: 24px;
  background-color: #4a5064;
  font-size: 10px;
  border-radius: none;
  color: #fff;
  text-align: center;
  letter-spacing: 0.3em;
  cursor: pointer;
}
</style>
