<template>
  <div class="container">
    <div class="login_box">
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" class="login_from">
        <h2 class="login_title">网站管理后台</h2>
        <!-- 帐号输入 -->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入用户名" prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>
        <!-- 密码输入 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" placeholder="请输入密码" prefix-icon="iconfont icon-3702mima" type="password"></el-input>
        </el-form-item>
        <!-- 验证码区 -->
        <el-row :gutter="10">
          <!-- 验证码输入框 -->
          <el-col :span="16">
            <el-form-item prop="code">
              <el-input v-model="loginForm.code" placeholder="请输入验证码"></el-input>
            </el-form-item>
          </el-col>
          <!-- 验证码图片 -->
          <el-col :span="8">
            <el-image style="height: 40px; border: 1px solid #dcdfe6; border-radius: 4px;" :src="verify.src" @click="verifyCode"></el-image>
          </el-col>
        </el-row>
        <!-- 提交按钮 -->
        <el-form-item class="login_btn">
          <el-button type="primary" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      verify: {},
      loginForm: {
        username: '',
        password: '',
        code: ''
      },
      // 表单验证
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录用户', trigger: 'blur' },
          {
            min: 5,
            max: 10,
            message: '长度在 5 到 10 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 5,
            max: 10,
            message: '长度在 5 到 10 个字符',
            trigger: 'blur'
          }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },

  mounted () {
    // 获取验证码
    this.verifyCode()
  },

  methods: {
    // 获取验证码
    verifyCode () {
      this.$http({
        url: 'captcha'
      }).then((backdata) => {
        const { data } = backdata.data
        this.verify = data
      })
    },

    // 登陆
    login () {
      // 表单预检验
      this.loginForm.uniqid = this.verify.uniqid
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('login', this.loginForm)
        if (res.code !== 200 && res.msg !== 'success') {
          return this.$message.error('帐号或密码不正确！')
        }
        this.$message.success('登陆成功')
        // 存储 token
        window.sessionStorage.setItem('token', res.data.token)
        // 跳转到后台首页
        this.$router.push('/home')
      })
    }
  }
}
</script>

<style scoped>
.container {
  background-color: #2b4b6b;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_box {
  background-color: #fff;
  width: 360px;
  padding: 40px 50px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px 50px rgba(0, 0, 0, 0.1) !important;
}

.login_title {
  text-align: center;
  margin: 0 0 30px;
  color: #2b4b6b;
}

.login_btn {
  text-align: center;
  margin: 10px 0;
}
</style>
