<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>访问数据</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-row>
      <el-col :span="24"
              class="ipdiv">
        <div ref="orderEchart"
             style="width:100%; height: 100%;"></div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="8">
        <div class="bdiv">
          <!-- 访客来源 -->
          <div ref="userEchart"
               style="width:100%; height: 100%;"></div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="bdiv">
          <!-- 用户访问地区语言 -->
          <div ref="langEchart"
               style="width:100%; height: 300px;"></div>
        </div>

      </el-col>
      <el-col :span="8">
        <div class="daydiv">
          <!-- 日历 -->
          <el-calendar v-model="dataValue"></el-calendar>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
      // 日历组件
      dataValue: new Date(),

      // 产品信息
      productListData: [],

      tabelDate: [],

      // 配置
      echartsData: {
        order: {
          title: {
            left: 'center',
            text: '7天访客记录'
          },
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: {
            type: 'value'
          },
          series: [],
          color: [
            '#409EFF'
          ]
        },
        user: {
          title: {
            left: 'center',
            text: '访问来源'
          },
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [],
              type: 'bar'
            }
          ],
          color: [
            '#409EFF'
          ]
        },
        lang: {
          title: {
            left: 'center',
            text: '访问者语种'
          },
          xAxis: {
            type: 'category',
            left: 'center',
            data: [],
            axisTick: {
              alignWithLabel: true
            }

          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [],
              type: 'bar'
            }
          ],
          color: [
            '#409EFF'
          ]
        }
      }

    }
  },

  methods: {
    async getLogData () {
      const { data: res } = await this.$http.get('iplog')
      if (res.code !== 200 && res.msg !== 'success') {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      const iplogDate = res.data.iplog
      const langarr = res.data.lang
      const browserarr = res.data.browser

      // 折线图
      this.echartsData.order.xAxis.data = iplogDate.map((item) => item.date)

      this.echartsData.order.series.push({
        data: iplogDate.map((item) => item.total),
        type: 'line'
      })

      const myEchartsOrder = echarts.init(this.$refs.orderEchart)
      myEchartsOrder.setOption(this.echartsData.order)

      // 柱状图
      this.echartsData.user.xAxis.data = browserarr.map((item) => item.browser)

      this.echartsData.user.series.push({
        data: browserarr.map((item) => item.total),
        type: 'bar'
      })

      const myEchartsUser = echarts.init(this.$refs.userEchart)
      myEchartsUser.setOption(this.echartsData.user)

      // 柱状图
      this.echartsData.lang.xAxis.data = langarr.map((item) => item.lang)
      this.echartsData.lang.series.push({
        data: langarr.map((item) => item.total),
        type: 'bar'
      })

      const myEchartsLang = echarts.init(this.$refs.langEchart)
      myEchartsLang.setOption(this.echartsData.lang)
    }
  },

  created () {
    this.getLogData()
  }

}
</script>

<style scoped>
.ipdiv {
  width: 100%;
  height: 435px;
  padding: 30px 0 5px 0;
  background-color: #fff;
}

.bdiv {
  height: 300px;
  margin-top: 20px;
  padding: 40px 0 0 50px;
  background-color: #fff;
  border-radius: 10px;
}

.daydiv {
  height: 300px;
  margin-top: 20px;
  padding: 10px 30px 30px;
  background-color: #fff;
  border-radius: 10px;
}
</style>
